import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { RequestStoreProvider } from './context/RequestContext';

ReactDOM.render(
  <React.StrictMode>
    <RequestStoreProvider>
      <App />
    </RequestStoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
