import { yupResolver } from '@hookform/resolvers/yup';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as React from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import XMLViewer from 'react-xml-viewer';
import * as yup from 'yup';

import { RequestStore } from '../context/RequestContext';

interface IEnvironment {
  environment: string;
}

const SendAccr = ({ history }: RouteComponentProps) => {
  const { state } = React.useContext(RequestStore);
  const location = useLocation<IEnvironment>();
  // const [token, setToken] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [messageField, setMessageField] = React.useState('');
  const editMessageRef = React.useRef<HTMLDivElement>(document.createElement('div'));
  const hiddenMessageRef = React.useRef<HTMLDivElement>(document.createElement('div'));
  const [reqURL, setReqURL] = React.useState('');
  // let tokenURL = ''; // Use if token needed for this enpoints
  // let reqClient = '';
  const customTheme = {
    tagColor: '#264653',
    textColor: '#e9c46a',
    separatorColor: '#2a9d8f',
  };
  const hiddenTheme = {
    tagColor: '#FFFFFF',
    textColor: '#FFFFFF',
    separatorColor: '#FFFFFF',
  };

  /// Get authorization token ///
  const setCredentials = (appEnv: string) => {
    switch (appEnv) {
      case 'int': {
        // tokenURL = 'https://auth.int.digital-retail-suite.vwcredit.io/oauth2/token';
        // reqClient = `grant_type=client_credentials&scope=drs-edocs/submit&client_secret=tbak484m2jo5n7up3q749tudt0klaphc1srtd024ljgrcpokf4e&client_id=5l04egbhfnbqg4pbrupjk7ovun`;
        setReqURL('https://api.int.digital-retail-suite.vwcredit.io/test-harness/submit-accr');
        break;
      }
      case 'dev': {
        // tokenURL = 'https://auth.dev.digital-retail-suite.vwcredit.io/oauth2/token';
        // reqClient = `grant_type=client_credentials&scope=drs-edocs/submit&client_secret=r26vlm6meoqrpcpp9h27d7afpt7glsokm5i51im6j13klvd0icc&client_id=2i41sm7fotuji62auc2s8frp8u`;
        setReqURL('https://api.dev.digital-retail-suite.vwcredit.io/test-harness/submit-accr');
        break;
      }
      default:
        // tokenURL = 'https://auth.int.digital-retail-suite.vwcredit.io/oauth2/token';
        // reqClient = `grant_type=client_credentials&scope=drs-edocs/submit&client_secret=tbak484m2jo5n7up3q749tudt0klaphc1srtd024ljgrcpokf4e&client_id=5l04egbhfnbqg4pbrupjk7ovun`;
        setReqURL('https://api.dev.digital-retail-suite.vwcredit.io/test-harness/submit-accr');
        break;
    }
  };
  /* const getToken = async () => {
    try {
      const response: AxiosResponse = await Axios.post(tokenURL, reqClient);

      if (response.data) {
        setToken(response.data.access_token);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('There was an error getting a new token!', err);
    }
  }; */

  /// Define form methods and validation  ///
  const requestSchema = yup.object().shape({
    message: yup.string().required('Please input the message.'),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(requestSchema),
  });

  React.useEffect(() => {
    setCredentials(location.state.environment);
    // getToken();
    setMessageField(state.message.replace(/&quot;/g, '"').replace(/\\"/g, '"'));
    setTimeout(() => setMessageField(hiddenMessageRef.current.innerText), 100);
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);
  const handleEditModalMessage = () => {
    let editModal: string | undefined = '';
    if (editMessageRef.current.innerText !== null) {
      editModal = editMessageRef.current.innerText;
    } else {
      editModal = '';
    }
    setMessageField(editModal);
    setShowModal(false);
  };

  const onSubmit = async () => {
    let stringMessage = messageField;
    stringMessage = stringMessage.replace(/(<(pre|script|style|textarea)[^]+?<\/\2)\s+|\s+(?=<|$)/g, '');
    stringMessage = stringMessage.replace(/\\n/g, '');
    stringMessage = stringMessage.replace(/\\r/g, '');
    stringMessage = stringMessage.replace(/(\r\n|\n|\r)/gm, '');

    setMessageField(stringMessage);
    apiRequest(stringMessage);
    history.push('/');
  };

  ///  Method that sends the message to Application Layer and returns an alert with the result ///
  const apiRequest = (msg: string): void => {
    const newMessage = {
      country: state.country,
      brand: state.brand,
      dealerId: state.dealerId,
      correlationId: state.correlationId,
      vendorName: state.vendorName,
      vendorId: state.vendorId,
      lenderAppId: state.lenderAppId,
      vendorAppId: state.vendorAppId,
      version: state.version,
      createdTime: state.createdTime,
      messageType: state.messageType,
      message: msg,
    };

    const config: AxiosRequestConfig = {
      method: 'post',
      url: reqURL,
      headers: {
        'Content-Type': 'application/json',
      },
      data: newMessage,
    };

    const sendMessage = async (conf: AxiosRequestConfig) => {
      try {
        // eslint-disable-next-line no-console
        // console.log(conf);
        const response: AxiosResponse = await Axios(conf);
        // eslint-disable-next-line no-alert
        if (response.status === 200) alert('Message sent successfuly!');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('There was an error sending the message to ACCR!', err);
      }
    };
    sendMessage(config);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleHideModal} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-3 mb-3 text-center">Edit XML Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={editMessageRef} style={{ fontSize: 'small' }} contentEditable suppressContentEditableWarning>
            <XMLViewer xml={messageField || ''} theme={customTheme} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleEditModalMessage()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="mt-3 mb-3 text-center">
          <Col>
            <h4>ACCR</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label>Make any necessary changes to the XML message and click submit.</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text onClick={handleShowModal}>Edit</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    onChange={(e) => setMessageField(e.target.value)}
                    ref={register}
                    name="message"
                    value={messageField}
                    as="textarea"
                    rows={34}
                    size="sm"
                    style={{ fontSize: 'small' }}
                  />
                  {errors.message && <Alert variant="warning">{errors.message.message}</Alert>}
                </InputGroup>
              </Form.Group>
              <Form.Group className="o-button-container">
                <button onClick={() => history.push('/accr')} type="button" className="c-btn  c-btn--secondary  o-button-container__button">
                  <span className="c-btn__text">Back</span>
                </button>
                <button type="submit" className="c-btn  o-button-container__button">
                  <span className="c-btn__text">Submit</span>
                </button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <div ref={hiddenMessageRef} style={{ height: '1px', overflow: 'hidden' }}>
        <XMLViewer xml={messageField} theme={hiddenTheme} />
      </div>
    </>
  );
};
export default withRouter(SendAccr);
