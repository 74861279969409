/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import Axios from 'axios';
import * as React from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import XMLViewer from 'react-xml-viewer';
import { AccrMessage } from '../AccrMessage';

const AccrMain = ({ history }: RouteComponentProps) => {
  const [accrMessages, setAccrMessages] = React.useState<any[]>([]);
  const [stage, setStage] = React.useState('dev');
  const [showModal, setShowModal] = React.useState(false);
  const [messageField, setMessageField] = React.useState('');
  const [searchDate, setSearchDate] = React.useState('');

  const customTheme = {
    tagColor: '#264653',
    textColor: '#e9c46a',
    separatorColor: '#2a9d8f',
  };

  const callAndSetAccrMessages = async (url: string) => {
    try {
      const response = await Axios.get(url);
      if (response.status === 200) {
        setAccrMessages(response.data);
      }
    } catch (err) {
      alert('Error getting ACCR messages');
      console.error('There was an error getting the messages', err);
    }
  };

  const getAllAccrs = async () => {
    const url = `https://api.${stage}.digital-retail-suite.vwcredit.io/test-harness/accrs`;
    callAndSetAccrMessages(url);
  };

  const searchClickHandler = () => {
    if (searchDate === '') {
      alert('Please input search Date');
    } else {
      const url = `https://api.${stage}.digital-retail-suite.vwcredit.io/test-harness/accrs?date=${searchDate}`;
      callAndSetAccrMessages(url);
    }
  };

  React.useEffect(() => {
    getAllAccrs();
  }, []);

  React.useEffect(() => {
    getAllAccrs();
  }, [stage]);

  const handleHideModal = () => setShowModal(false);
  const handleClick = (msg: string) => {
    setMessageField(msg);
    setShowModal(true);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleHideModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="mt-3 mb-3 text-center">Converted message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: 'small', overflow: 'scroll' }}>
            <XMLViewer xml={messageField || ''} theme={customTheme} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="mt-4 mb-5 text-center">
          <Col>
            <h4>ACCR converted messages</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Label column sm={2}>
                Select environment
              </Form.Label>
              <Col sm={2}>
                <Form.Control as="select" onChange={(e) => setStage(e.target.value)} id="stageOption" name="stageOption">
                  <option value="dev">dev</option>
                  <option value="int">int</option>
                </Form.Control>
              </Col>
              <Col sm={6} style={{ display: 'flex', padding: '0px 100px 0px 100px' }}>
                <label htmlFor="" className="form-label col-form-label">
                  Date
                </label>
                <input
                  type="date"
                  name="duedate"
                  placeholder="Due date"
                  style={{ margin: '0px 6px 0px 6px' }}
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                />
                <button onClick={searchClickHandler} type="button" className="c-btn ">
                  Search
                </button>
              </Col>
              <Col sm={2}>
                <button onClick={() => history.push('/accr')} type="button" className="c-btn ">
                  Create new ACCR message
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th>Received</th>
                  <th>Id</th>
                  <th>Brand</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {accrMessages
                  .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1))
                  .map((accr: AccrMessage) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={accr.id}>
                      <td>{accr.createdDate}</td>
                      <td>{accr.accrRequest.id}</td>
                      <td>{accr.accrRequest.brand}</td>
                      <td style={{ display: 'flex', justifyContent: 'center' }}>
                        <button onClick={() => handleClick(accr.accrRequest.message)} type="button" className="c-btn  o-button-container__button">
                          View message
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(AccrMain);
