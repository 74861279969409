import * as React from 'react';

import { IAction, IContractValidationRequest, IProps } from '../type';

const initialRequest: IContractValidationRequest = {
  country: '',
  brand: '',
  dealerId: '',
  correlationId: '',
  vendorName: '',
  vendorId: '',
  lenderAppId: '',
  vendorAppId: '',
  version: '',
  createdTime: new Date(),
  messageType: '',
  message: '',
};

export const RequestStore = React.createContext<IContractValidationRequest | any>(initialRequest);

const reducer = (state: IContractValidationRequest, action: IAction): IContractValidationRequest => {
  switch (action.type) {
    case 'POPULATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const RequestStoreProvider = (props: IProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialRequest);
  return (
    <RequestStore.Provider value={{ state, dispatch }}>
      {
        // eslint-disable-next-line react/destructuring-assignment
        props.children
      }
    </RequestStore.Provider>
  );
};
