import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Header = ({ history }: RouteComponentProps) => {
  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand href="#home">
        <h5>DRS TestHarness</h5>
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link onClick={() => history.push('/')}>Home</Nav.Link>
        <Nav.Link onClick={() => history.push('/contract-validation')}>Contract validation</Nav.Link>
        <Nav.Link onClick={() => history.push('/contract-validation-dealertrack')}>Contract validation (Dealertrack)</Nav.Link>
        <Nav.Link onClick={() => history.push('/accr-main')}>ACCR</Nav.Link>
        <Nav.Link onClick={() => history.push('/edoc')}>eDocs</Nav.Link>
        <Nav.Link onClick={() => history.push('/edoc-dealertrack')}>eDocs (Dealertrack)</Nav.Link>
      </Nav>
    </Navbar>
  );
};
export default withRouter(Header);
