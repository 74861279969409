import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as yup from 'yup';

import { RequestStore } from '../context/RequestContext';

const AccrInputMessage = ({ history }: RouteComponentProps) => {
  const { dispatch } = React.useContext(RequestStore);

  const fullMessageSchema = yup.object().shape({
    selectedEnv: yup.string(),
    fullMessage: yup
      .object()
      .required('Please input the full message.')
      .shape({
        country: yup.string().required('country'),
        brand: yup.string().required('brand'),
        dealerId: yup.string().required('dealerId'),
        correlationId: yup.string().required('correlationId'),
        vendorName: yup.string().required('vendorName'),
        vendorId: yup.string().required('vendorId'),
        lenderAppId: yup.string().required('lenderAppId'),
        vendorAppId: yup.string().required('vendorAppId'),
        version: yup.string().required('version'),
        createdTime: yup.string().required('createdTime'),
        messageType: yup.string().required('messageType'),
      }),
  });

  type FullMessage = yup.InferType<typeof fullMessageSchema>;

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(fullMessageSchema),
  });

  const onSubmit = (formData: FullMessage): void => {
    const data = formData.fullMessage;
    dispatch({
      type: 'POPULATE',
      payload: data,
    });
    history.push({
      pathname: '/send-accr',
      state: { environment: formData.selectedEnv },
    });
    reset();
  };

  return (
    <Container>
      <Row className="mt-4 mb-4 text-center">
        <Col>
          <h4>ACCR</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Row>
                <Form.Label column sm={3}>
                  Select environment
                </Form.Label>
                <Col sm={4}>
                  <Form.Control ref={register} name="selectedEnv" as="select" className="mb-3">
                    <option>dev</option>
                    <option>int</option>
                  </Form.Control>
                </Col>
              </Row>
              <Form.Label>Input the full message in JSON format.</Form.Label>
              <Form.Control ref={register} name="fullMessage" as="textarea" rows={15} style={{ fontSize: 'medium' }} />
              {errors.fullMessage && <Alert variant="warning">{errors.fullMessage.message}</Alert>}
            </Form.Group>
            <Form.Group className="o-button-container">
              <button onClick={() => reset()} type="button" className="c-btn  c-btn--secondary  o-button-container__button">
                <span className="c-btn__text">Clear</span>
              </button>
              <button type="submit" className="c-btn  o-button-container__button">
                <span className="c-btn__text">Next</span>
              </button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(AccrInputMessage);
