import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as yup from 'yup';

import { RequestStore } from '../context/RequestContext';

const EdocInputMessage = ({ history }: RouteComponentProps) => {
  const { dispatch } = React.useContext(RequestStore);

  const fullMessageSchema = yup.object().shape({
    selectedEnv: yup.string(),
    requestHeaders: yup
      .object()
      .required('Please input the requestHeaders.')
      .shape({
        country: yup.string().required('country'),
        brand: yup.string().required('brand'),
        dealerId: yup.string().required('dealerId'),
        correlationId: yup.string().required('correlationId'),
        vendorName: yup.string().required('vendorName'),
        vendorId: yup.string().required('vendorId'),
        lenderAppId: yup.string().required('lenderAppId'),
        vendorAppId: yup.string().required('vendorAppId'),
        version: yup.string().required('version'),
        createdTime: yup.string().required('createdTime'),
        messageType: yup.string().required('messageType'),
      }),
    fullMessage: yup.string().required('Please input the message.').min(5, 'Please input a full message.'),
  });

  type FullMessage = yup.InferType<typeof fullMessageSchema>;

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(fullMessageSchema),
  });

  const onSubmit = (formData: FullMessage): void => {
    dispatch({
      type: 'POPULATE',
      payload: {
        country: formData.requestHeaders.country,
        brand: formData.requestHeaders.brand,
        dealerId: formData.requestHeaders.dealerId,
        correlationId: formData.requestHeaders.correlationId,
        vendorName: formData.requestHeaders.vendorName,
        vendorId: formData.requestHeaders.vendorId,
        lenderAppId: formData.requestHeaders.lenderAppId,
        vendorAppId: formData.requestHeaders.vendorAppId,
        version: formData.requestHeaders.version,
        createdTime: formData.requestHeaders.createdTime,
        messageType: formData.requestHeaders.messageType,
        message: formData.fullMessage,
      },
    });
    history.push({
      pathname: '/send-edoc-dealertrack',
      state: { environment: formData.selectedEnv },
    });
    reset();
  };

  return (
    <Container>
      <Row className="mt-4 mb-4 text-center">
        <Col>
          <h4>eDocs (Dealertrack)</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Row>
                <Form.Label column sm={3}>
                  Select environment
                </Form.Label>
                <Col sm={4}>
                  <Form.Control ref={register} name="selectedEnv" as="select" className="mb-3">
                    <option>dev</option>
                    <option>int</option>
                  </Form.Control>
                </Col>
              </Row>

              <Form.Label>Input the request headers in JSON format.</Form.Label>
              <Form.Control ref={register} name="requestHeaders" as="textarea" rows={8} style={{ fontSize: 'medium' }} />
              {errors.requestHeaders && <Alert variant="warning">{JSON.stringify(errors.requestHeaders)}</Alert>}

              <Form.Label>Input the full message in XML format.</Form.Label>
              <Form.Control ref={register} name="fullMessage" as="textarea" rows={15} style={{ fontSize: 'small' }} />
              {errors.fullMessage && <Alert variant="warning">{errors.fullMessage.message}</Alert>}
            </Form.Group>
            <Form.Group className="o-button-container">
              <button onClick={() => reset()} type="button" className="c-btn  c-btn--secondary  o-button-container__button">
                <span className="c-btn__text">Clear</span>
              </button>
              <button type="submit" className="c-btn  o-button-container__button">
                <span className="c-btn__text">Next</span>
              </button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(EdocInputMessage);
