/* eslint-disable react/button-has-type */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Home = ({ history }: RouteComponentProps) => {
  return (
    <Container className="fluid">
      <Row className="mt-5 mb-5 text-center">
        <Col>
          <h1>Select the action you want to test</h1>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => history.push('/contract-validation')} type="button" className="c-btn  o-button-container__button">
            Contract Validation
          </button>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => history.push('/contract-validation-dealertrack')} type="button" className="c-btn  o-button-container__button">
            Contract Validation (Dealertrack)
          </button>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => history.push('/accr-main')} type="button" className="c-btn  o-button-container__button">
            ACCR
          </button>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => history.push('/edoc')} type="button" className="c-btn  o-button-container__button">
            eDocs
          </button>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => history.push('/edoc-dealertrack')} type="button" className="c-btn  o-button-container__button">
            eDocs (DealerTrack)
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Home);
