import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AccrInputMessage from './components/AccrInputMessage';
import AccrMain from './components/AccrMain';
import EdocInputMessage from './components/EdocInputMessage';
import EdocInputMessageDealerTrack from './components/EdocInputMessageDealerTrack';
import Header from './components/Header';
import Home from './components/Home';
import InputMessage from './components/InputMessage';
import DealerTrackInputMessage from './components/DealerTrackInputMessage';
import SendAccr from './components/SendAccr';
import SendEdoc from './components/SendEdoc';
import SendEdocDealerTrack from './components/SendEdocDealerTrack';
import SendRequest from './components/SendRequest';
import DealerTrackSendRequest from './components/DealerTrackSendRequest';

const App: React.FC = (): React.ReactElement => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contract-validation" exact component={InputMessage} />
          <Route path="/contract-validation-dealertrack" exact component={DealerTrackInputMessage} />
          <Route path="/accr-main" exact component={AccrMain} />
          <Route path="/accr" exact component={AccrInputMessage} />
          <Route path="/send-request" exact component={SendRequest} />
          <Route path="/send-request-dealertrack" exact component={DealerTrackSendRequest} />
          <Route path="/send-accr" exact component={SendAccr} />
          <Route path="/edoc" exact component={EdocInputMessage} />
          <Route path="/edoc-dealertrack" exact component={EdocInputMessageDealerTrack} />
          <Route path="/send-edoc" exact component={SendEdoc} />
          <Route path="/send-edoc-dealertrack" exact component={SendEdocDealerTrack} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
